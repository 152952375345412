import React , {useState , useEffect} from 'react';
import ReactDOM from "react-dom";



import FlexContainer from 'react-styled-flexbox';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { borders } from '@material-ui/system';
import flexbox from '@material-ui/system';

import Calendar from 'react-calendar';


const titleStyle = {
fontWeight: 'bold',
color: '#6D5C1C',
fontSize: '18px',
}

function LoadCalendar() {
  const  datem = new Date();

  return (
    <div>
      <div style={titleStyle}> MONTHLY CALENDAR </div>
    <Calendar  value={datem} calendarType="US" />
    <br />
    <a  href="documents/RPEA_Chapter_031_Meeting_dates_for_2025.pdf"  style={{ textAlign: 'center' , fontSize: '20px' , fontWeight: 'bold'}}> CLICK HERE FOR 2025 MEETING DATES </a>
    <br /><br />
    </div>
);
}

export default LoadCalendar;
